<template>
  <v-navigation-drawer
    :value="drawer"
    @input="toggleDrawer($event)"
    app
    temporary
    width="250px"
  >
    <v-sheet>
      <v-sheet class="mx-auto" flat>
        <v-sheet class="ml-4 py-2">
          <svg
            id="Layer_1"
            data-name="Layer 1"
            xmlns="http://www.w3.org/2000/svg"
            width="100"
            height="50"
            viewBox="0 0 1832.468 730.645"
          >
            <g>
              <path
                d="M1529.314,495.664c8.018-10.207,15.9-20.523,24.086-30.594,13.052-16.058,27.6-30.467,45.978-40.638,13.014-7.2,26.585-11.471,41.742-10.1a29.647,29.647,0,0,1,27.32,28.888c.24,12.61-3.341,24.258-8.756,35.5-2.039,4.234-3.682,8.657-5.5,12.994l1.866,1.162c2.769-3.416,5.889-6.609,8.25-10.283,13.526-21.053,30.957-38.412,51.227-52.889,15.561-11.114,32.756-17.335,52.506-15.369,15.336,1.526,28.144,14.161,28.772,29.453.7,17.032-3.631,33.067-9.972,48.611q-14.239,34.905-29.078,69.566c-7.644,17.747-15.862,35.248-23.793,52.872-2.93,6.512-8.3,9.337-15.093,9.523q-15.27.417-30.555.255c-11.572-.113-14.855-4.973-10.395-15.817,5.506-13.388,11.508-26.573,17.175-39.9,7.449-17.516,14.768-35.087,22.165-52.625,5.421-12.853,11.067-25.616,16.241-38.566a168.168,168.168,0,0,0,6.592-20.626c.476-1.856-.618-5.439-2.037-6.14-2.265-1.121-5.97-1.533-8.065-.405a81.356,81.356,0,0,0-15.571,10.559c-25.421,22.636-45.46,49.554-61.7,79.228-13.46,24.587-26.211,49.558-39.29,74.352-3.559,6.748-8.783,10.533-16.868,10.44-9.7-.111-19.415.414-29.124.658-10.685.27-13.765-4.222-9.916-13.8,9.187-22.854,18.08-45.825,27.269-68.678,9.361-23.281,18.972-46.463,28.385-69.724a80.206,80.206,0,0,0,4.627-13.7c.557-2.708.377-7.132-1.276-8.336-2.191-1.6-6.821-2.063-9.211-.8a99.973,99.973,0,0,0-18.661,12.447c-16.918,14.677-30.694,32.054-43.206,50.579-19.585,29-34.218,60.557-49.333,91.891-1.049,2.176-2.6,4.168-3.327,6.434-4.085,12.807-13.116,16.832-25.923,15.556-6.732-.671-13.6.174-20.4-.067-10.1-.357-13.442-6.3-9.81-15.735,5.8-15.059,11.118-30.3,16.683-45.449,4.817-13.114,9.711-26.2,14.539-39.311,5.661-15.368,11.177-30.79,16.961-46.111q8.281-21.939,17.013-43.707c3.367-8.415,9.721-12.109,18.956-11.49,7.262.486,14.591-.042,21.857.407,9.857.608,12.785,6.591,8.559,15.516-2.881,6.084-4.6,12.7-7.362,18.852-3.341,7.451-7.266,14.642-10.861,21.983-1.962,4.006-3.766,8.089-5.64,12.137Z"
              />
              <path
                d="M.6,673.018c0-5.556-.185-11.12.048-16.666.279-6.664,2.719-12.484,9.11-15.419,6.313-2.9,13.071-2.053,17.572,2.952,7.5,8.345,13.892,17.675,20.923,26.453,17.839,22.268,55.408,26.062,81.968,20.751,35.529-7.1,69.127-32.706,73.909-70.022a79.513,79.513,0,0,0-6.936-44.922c-7.189-14.737-15.328-29.338-23.314-43.662-7.393-13.26-15.308-25.879-22.691-39.143-11.965-21.494-22.056-49-21.062-73.854a100.956,100.956,0,0,1,8.557-37.895c5.827-12.859,15.488-23.062,26.2-32.261,38.068-32.7,107.9-52.721,152.3-20.149,7.634,5.6,7.842,11.818.828,17.916q-15.329,13.326-30.763,26.527c-6.336,5.408-14.431,4.944-20.066-1.223-3.27-3.579-6.419-7.262-9.59-10.928-17.483-20.21-47.877-8.306-63.695,7.775-22.036,22.4-12.551,53.154-.67,78.017,1.261,2.639,2.562,5.258,3.878,7.869,13.237,26.27,31.306,47.852,47.3,72.17,14.216,21.618,26.807,48.661,25.588,75.071-1.676,36.288-29.6,77.433-58.314,98.787-24.064,17.9-54.595,28.056-84.54,29.35-32.3,1.4-66.413-8.392-97.078-17.6-13.82-4.152-26.238-8-29.478-23.275-1.129-5.32-.256-11.059-.256-16.615Z"
              />
              <path
                d="M660.925,507.237c3.833-5.035,7.682-10.057,11.5-15.106,19.954-26.416,41.958-50.638,70.964-67.761,13.389-7.9,27.779-10.786,42.66-7.987,15.273,2.874,29,18.07,26.256,37.145a184.734,184.734,0,0,1-13.431,47.155c-13.3,30.835-25.982,61.934-39.091,92.852-3.2,7.553-7.079,14.822-10.627,22.231-3.461,7.228-9.354,11.139-17.234,11.471-9.458.4-18.954.511-28.411.165-9.809-.36-13.738-5.807-10.261-15.062,6.451-17.17,13.522-34.116,20.557-51.064,9.524-22.946,19.327-45.777,28.863-68.719,3.515-8.458,6.624-17.084,9.884-25.646.686-1.8,1.779-3.69,1.69-5.49-.131-2.656-.338-6.33-2.021-7.611-1.788-1.361-5.813-1.393-8.02-.311a72.4,72.4,0,0,0-14.013,9.044c-16.937,13.934-31.211,30.282-43.618,48.316C664.593,542.8,646,576.642,628.785,611.286a62.554,62.554,0,0,1-5.573,9.32c-3.641,4.935-8.363,7.614-14.944,7.259-7.992-.431-16.032.109-24.041-.12-11.74-.337-15.346-6.459-11.229-17.182,8.909-23.208,17.313-46.607,26.1-69.862,7.5-19.847,15.306-39.578,22.861-59.4,4.808-12.618,9.406-25.316,14.1-37.979.587-1.585,1.2-3.164,1.739-4.764,2.877-8.472,8.252-16.553,17.978-17.3,3.072-.237,6.16.039,9.238-.085,11.154-.452,21.7,1.768,20.457,15.5a60.141,60.141,0,0,1-3.988,17.561c-6.872,16.48-14.436,32.674-21.7,48.992a16.465,16.465,0,0,0-.694,2.627Z"
              />
              <path
                d="M1352.156,539.409a62.16,62.16,0,0,0-5.188,5.928c-18.761,27.633-40.411,52.561-68.613,71.105-13.477,8.861-27.72,15.687-44.812,14.383-17.617-1.345-31.679-15.687-31.944-33.121-.24-15.835,4.791-30.392,10.284-44.839,4.879-12.835,10.066-25.559,15.376-38.225,4.476-10.673,9.3-21.2,14.013-31.775,7.261-16.31,14.75-32.523,21.764-48.938,4.727-11.062,8.157-13.659,20.21-13.932,7.521-.171,15.031-.777,22.551-1.01a31.4,31.4,0,0,1,7.952.632c7.414,1.71,10.224,6.561,7.445,13.506-6,15-12.292,29.883-18.533,44.785-7.554,18.039-15.412,35.956-22.705,54.1-6.389,15.894-12.194,32.02-18.211,48.06a24.085,24.085,0,0,0-1.247,4.911c-.871,5.93,2.046,8.885,7.971,7.52a25.662,25.662,0,0,0,8.439-3.964c17.632-12.085,31.421-27.975,43.973-44.955,24.529-33.185,44.223-69.226,62.668-105.983.867-1.729,1.862-3.4,2.628-5.173,2.966-6.87,8.52-12.852,16.365-13.5,3.035-.249,6.084.077,9.128.126,8.495.139,18.629-2.689,26.549,1.4,5.923,3.06,5.234,10.342,3,15.593-6.69,15.752-12.773,31.767-18.806,47.785-10.9,28.942-21.528,57.985-32.362,86.951-4.4,11.758-8.717,23.564-13.709,35.075-2.443,5.633-5.285,11.9-9.809,15.666-9.013,7.5-20.274,5.586-30.539,2.74-6.561-1.819-9.758-9.759-7.959-17.644a137.79,137.79,0,0,1,7.016-22.051c5.543-13.365,11.73-26.467,17.612-39.7a37.5,37.5,0,0,0,1.275-3.967Z"
              />
              <path
                d="M469.1,626.367c-4.617,0-9.25.242-13.847-.052-9.03-.578-12.461-7.017-9.169-15.222,7.522-18.748,14.591-37.676,21.837-56.534,3.194-8.311,6.489-16.586,9.52-24.955,3.024-8.346,5.742-16.8,8.663-25.185,1.028-2.95,2.43-5.777,3.36-8.753,1.717-5.492,3.988-10.965,4.635-16.6,1.378-12.014-10.461-22.237-22.16-18.725a54.083,54.083,0,0,0-17.6,9.7c-19.112,15.293-32.55,35.142-42.822,56.965-8.011,17.021-15.016,34.536-21.78,52.094-2.231,5.788-4.615,14.773-4.124,21.024.179,2.291,1.422,4.651,3.577,5.484a7.386,7.386,0,0,0,4.848-.169c6.5-2.073,11.388-7.712,16.429-12.007,6.033-5.14,11.979-10.376,17.765-15.793,3.234-3.028,6.079-5.7,10.709-4.092,4.582,1.595,4.679,5.589,4.819,9.579a24.557,24.557,0,0,1-7.711,18.956c-20.88,20.238-43.97,37.3-72.137,46.294-19.173,6.121-39.382-4.336-43.965-24.3-5.167-22.5,5.924-46.4,16.243-65.763,15.895-29.828,34.9-57.429,60.2-80.36,19.107-17.323,39.314-33.091,63.474-42.875a88.085,88.085,0,0,1,22.326-5.808,31.21,31.21,0,0,1,21.491,5.139c7.886,5.266,9.021,4.822,13.163-3.255,2.576-5.024,6.376-7.807,12.048-7.886,9.719-.134,19.444-.448,29.156-.23,10.224.229,13.8,6.193,9.488,15.652-19.764,43.375-36.525,87.918-52.49,132.775-4.777,13.422-9.5,26.863-14.37,40.252-3.971,10.917-9.711,14.79-21.367,14.759-3.4-.009-6.8,0-10.206,0Z"
              />
              <path
                d="M1194.767,442.388c-9.444,0-18.889.073-28.331-.026-6.061-.064-9.289,3.213-11.149,8.526q-14.847,42.4-29.77,84.772-14.094,39.945-28.34,79.838c-4.114,11.467-11.637,16.468-23.688,16.337-6.071-.066-12.151.129-18.212-.138-11.855-.521-18.859-8.377-16.227-19.767,3.137-13.573,7.6-26.852,11.677-40.2,2.6-8.5,5.47-16.929,8.383-25.334s6.129-16.7,9.031-25.105c5.649-16.364,11.105-32.794,16.778-49.15,2.369-6.831,4.981-13.584,7.674-20.3,2.872-7.156,2.174-8.322-5.757-8.283-5.584.028-11.177.055-16.748.384-8.95.527-12.929-1.064-14.571-6.242-1.858-5.857,1.795-16.129,7.256-19.185a33.1,33.1,0,0,1,9.5-3.508q14.252-2.859,28.634-5.058c5.866-.907,9.448-4.522,12.488-9.078,8.18-12.261,16.615-24.364,24.467-36.83,4.954-7.865,11.672-11.307,20.732-10.537a110.046,110.046,0,0,1,14.421,1.822c8.444,1.87,10.979,6.945,8.073,15.053-3.977,11.1-7.973,22.191-11.879,33.313-1.378,3.924,1.052,6.121,6.337,5.994,10.428-.252,20.857-.5,31.288-.67a38.714,38.714,0,0,1,7.255.445c8.028,1.406,10.553,5.763,8.235,13.525a76.889,76.889,0,0,0-2.736,11.221c-.74,5.289-3.89,7.662-8.8,7.894-5.329.253-10.679.058-16.021.058Z"
              />
              <path
                d="M829.7,577.983c0-1.074.01-2.147.035-3.218.757-32.28,13.674-60.63,32.882-86.177,11.956-15.9,24.642-31.259,40.312-43.612,22.893-18.049,47.935-31.969,77.615-35.8a49.968,49.968,0,0,1,29.64,5.151c4.574,2.306,7.126,5.876,6.62,11.038a41.559,41.559,0,0,1-1.885,9.172c-1.972,5.844-5.748,10.716-12.469,10.4-6.412-.3-11.587-5.016-17.932-5.562-7.475-.643-14,2.487-19.982,6.7-15.35,10.8-26.647,25.271-37.193,40.373-15.532,22.24-26.4,46.061-30.5,72.943-.844,5.525-1.451,11.2-.284,16.67s4.376,10.76,9.439,13.19c3.378,1.622,7.257,1.85,11.007,1.731a66.765,66.765,0,0,0,25.686-6.1c8.43-3.878,15.517-9.907,23.221-14.961,7.78-5.105,15.108-1.307,15.818,7.994,1.423,18.663-32.038,34.818-46.264,41.16-24.645,10.987-57.379,19.518-81.114,3.22-10.577-7.263-19.722-17.409-23.007-29.765A57.116,57.116,0,0,1,829.7,577.983Z"
              />
            </g>
            <g>
              <path
                d="M847.181,149.262c10.046,5.745,20,11.74,30.158,17.19,40.2,21.559,82.262,36.652,125.406,47.48,26.857,6.74,53.9,12.809,81.082,17.357,25.861,4.328,52,6.492,78.04,9.243,39.066,4.126,78.252,4.725,117.423,3.6,20.965-.6,41.926-2.624,62.827-4.814,57.255-6,113.278-19.74,168.634-37.491a1133.926,1133.926,0,0,0,134.2-52.814c1.116-.522,2.292-.867,5.016-1.88a42.238,42.238,0,0,1-3.777,3.5c-67.261,44.375-137.268,81.337-211.646,106.437C1374,277.5,1312.315,288.981,1249.152,287.934c-88.482-1.466-175.367-14.753-259.368-48.737A502.344,502.344,0,0,1,871.975,172.44c-8.813-6.747-16.952-14.689-25.4-22.082Z"
              />
              <path
                d="M845.976,136.775c14.713-11.528,29.033-23.819,44.209-34.448C946.912,62.6,1008.579,37.77,1073,21.707,1140.256,4.939,1208.367-1.763,1277.033.391c58.31,1.828,115.059,15.008,170.729,34.986,69.926,25.093,135.921,60.548,199.462,102.524a3.933,3.933,0,0,1,1.835,2.5c-9.509-4.234-19.031-8.427-28.525-12.707a1064.9,1064.9,0,0,0-147.277-53.32,857.384,857.384,0,0,0-118.575-24.959A713.61,713.61,0,0,0,1218.5,43.652c-31.94,1.783-63.972,3.215-95.719,7.349-34.2,4.454-68.3,10.821-102.13,18.251-50.883,11.173-100.3,28.462-147.4,53.964-8.9,4.82-17.557,10.268-26.327,15.427Z"
              />
              <path
                d="M1666.08,138.427c57.788-41.281,113.263-86.164,166.2-138.114,0,14.638.159,27.569-.191,40.482-.047,1.718-2.437,3.882-4.151,4.928-36.365,22.2-72.617,44.677-109.281,66.194-16.461,9.66-33.767,17.357-50.7,25.927A11.163,11.163,0,0,1,1666.08,138.427Z"
              />
              <path
                d="M1665.421,148.6c3.272,1.509,6.581,2.921,9.812,4.541,52.071,26.113,103.028,54.825,151.987,88.248,3.681,2.513,5.4,5.083,5.237,10.265-.36,11.422-.112,22.869-.112,35.789C1779.331,235.433,1723.459,190.348,1665.421,148.6Z"
              />
            </g>
          </svg>
        </v-sheet>
        <v-divider></v-divider>
        <v-list>
          <v-list-item-group
            v-model="docsDrawer.doc"
            color="cyan darken-4"
            mandatory
          >
            <v-list-item v-for="doc in docs" :key="doc.key">
              <v-list-item-icon>
                <v-icon v-text="doc.icon"></v-icon>
              </v-list-item-icon>

              <v-list-item-content>
                <v-list-item-title
                  v-text="doc.title"
                  class="font-weight-light text-h6"
                ></v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list-item-group>
        </v-list>
        <v-container>
          <v-row>
            <v-spacer></v-spacer>
            <v-btn
              v-if="this.$store.state.userIsCoreMember"
              fab
              color="green"
              class="my-6 mr-3"
              @click="newDocForm = true"
            >
              <v-icon color="white">mdi-plus</v-icon>
            </v-btn>
            <v-spacer></v-spacer>
          </v-row>
        </v-container>
      </v-sheet>
      <v-dialog v-model="newDocForm" max-width="600px">
        <v-card>
          <v-card-text>
            <v-container>
              <v-text-field
                label="Document Name"
                required
                v-model="documentName"
              >
              </v-text-field>
              <v-chip-group
                mandatory
                active-class="primary--text"
                v-model="docType"
              >
                <v-chip v-for="type in tags" :key="type">
                  {{ type }}
                </v-chip>
              </v-chip-group>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="newDocForm = false">
              Cancel
            </v-btn>
            <v-btn color="blue darken-1" text @click="createNewDoc()">
              Save
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-sheet>
  </v-navigation-drawer>
</template>

<script>
import { firebaseApp } from "../firebase/init";

import "firebase/firestore";

export default {
  name: "DocsSideBar",
  data: function () {
    return {
      newDocForm: false,
      documentName: "",
      docType: 0,
      tags: ["Document", "Checklist"],
    };
  },
  computed: {
    drawer() {
      return this.$store.state.drawerExtended.docs;
    },
    docsDrawer() {
      return this.$store.state.docsDrawer;
    },
    docs() {
      return this.$store.state.docs;
    },
  },
  methods: {
    toggleDrawer($event) {
      this.$store.commit("toggleDrawer", ["docs", $event]);
    },
    newDoc() {
      this.newDocForm = true;
    },
    createNewDoc() {
      let kabobName = this.documentName.toLowerCase().replace(/ /g, "-");
      firebaseApp
        .firestore()
        .collection("docs")
        .doc(kabobName)
        .set({
          content: [],
          icon:
            this.docType === 0 ? "mdi-file-document-outline" : "mdi-check-all",
          name: this.documentName,
          title: this.documentName,
          key: kabobName,
        });
      this.newDocForm = false;
      this.documentName = "";
      this.docType = 0;
    },
  },
};
</script>
